import React, { FC, Suspense } from 'react';
import Loader from '../Loader/Loader';

export default function withSuspenseFallback<T extends unknown>(
  Component: FC<T>
) {
  return function DefaultFallback(
    props: JSX.IntrinsicAttributes & { children?: React.ReactNode } & T
  ) {
    return (
      <Suspense
        fallback={
          <div className="ant-layout-content flex-center">
            <Loader />
          </div>
        }>
        <Component {...props} />
      </Suspense>
    );
  };
}