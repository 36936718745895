import withSuspenseCallback from './withSuspenseCallback';
import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

const HomePage = withSuspenseCallback(
    React.lazy(
        () => import('../../pages/HomePage/HomePage')
    )
);

const AppRouter = () => {
    return(
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<HomePage />} ></Route>
          </Routes>
        </BrowserRouter>
    )
};

export default AppRouter;